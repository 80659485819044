<template>
	<div class="addr">
		<el-amap :center="center" :zoom="zoom" :zoom-enable="zoomEnable" :drag-enable="dragEnable">
			<el-amap-marker :position="center" :label="label"></el-amap-marker>
		</el-amap>
	</div>
</template>
<script>
	export default {
		name: "bjaddr",
		data() {
			return {
				//中心坐标的经纬度
				center: [116.332091,40.041456],
				//地图的缩放等级  
				zoom: 20,
				//是否缩放
				zoomEnable: false,
				//是否拖动  
				dragEnable: false,
				label: {
					content: "中正数服（北京）分部",
					offset: [-55, -20],
				},
			};
		},
	};
</script>

<style lang="scss" scoped>
	.addr{
		width: 784px;
		height: 460px;
	}
</style>
