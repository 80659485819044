<template>
	<div class="connect">
		<!-- 回到顶部 -->
		<goTop></goTop>
		<transition name="fade">
			<div class="max-box" v-show="show">
				<header>
					<!-- 上边框虚线 -->
					<div class="top"></div>
					<div class="center">
						<img src="@/assets/img/connect/connect-banner.png">
					</div>
					<!-- 下边框虚线 -->
					<div class="bottom"></div>
				</header>
				<main>
					<div class="top">
						<!-- 逗号图片 -->
						<div class="douhao">
							<img src="@/assets/img/connect/douhao-icon.png">
						</div>
						<el-row>
						  <el-col :span="9">
							  <div class="grid-content bg-purple">
								  <h1>
									  杭州公司  
								  </h1>
								  <!-- 渐变线 -->
								  <div class="line-linear"></div>
								  <ul>
								  	<li>
										<i class="el-icon-location"></i>
										杭州市滨江区滨文路15号丰利中心12F
									</li>
									<li>
										<img src="@/assets/img/connect/web-icon.png" >
										<a href="https://www.tockdata.com" style="color: #666;">https://www.tockdata.com</a>
									</li>
									<li>
										<img src="@/assets/img/connect/iphone-icon.png" >
										13832229992
									</li>
									<li>
										<img src="@/assets/img/connect/female-icon.png" >
										施女士 
									</li>
									<li>
										<img src="@/assets/img/connect/email-icon.png" style="margin-top: 1px;" >
										tour@tockdata.com
									</li>
								  </ul>
							  </div>
							  </el-col>
						  <el-col :span="15">
							  <div class="grid-content bg-purple-light">
								 <div class="amap-page-container">
									 <!-- 杭州地图 -->
								    <hzaddr></hzaddr>
								 
								 
								   </div>
							  </div>
							  </el-col>
						</el-row>
						<el-row>
						  <el-col :span="9">
							  <div class="grid-content bg-purple">
								  <h1>
									  北京公司  
								  </h1>
								  <!-- 渐变线 -->
								  <div class="line-linear"></div>
								  <ul>
								  	<li>
										<i class="el-icon-location"></i>
										北京市海淀区安宁庄东路23号院2层
									</li>
									<li>
										<img src="@/assets/img/connect/web-icon.png" >
										<a href="https://www.tockdata.com" style="color: #666;">https://www.tockdata.com</a>
									</li>
									<li>
										<img src="@/assets/img/connect/iphone-icon.png" >
										13832229992
									</li>
									<li>
										<img src="@/assets/img/connect/female-icon.png" >
										施女士 
									</li>
									<li>
										<img src="@/assets/img/connect/email-icon.png" style="margin-top: 1px;">
										tour@tockdata.com
									</li>
								  </ul>
							  </div>
							  </el-col>
						  <el-col :span="15">
							  <div class="grid-content bg-purple-light">
								 <!-- 北京地图 -->
								 <bjaddr></bjaddr>
							  </div>
							  </el-col>
						</el-row>
					</div>
				
					
				</main>
				<div class="more">
					<img src="@/assets/img/more.png">
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	// 杭州地图
	import hzaddr from '@/components/hzaddr.vue'
	// 北京地图
	import bjaddr from '@/components/bjaddr.vue'
	// 回到顶部
	import goTop from '@/components/goTop.vue'
	export default {
		name:'Connect',
	        data(){
	          const self = this;
	          return {
				  // 默认不展示
				  show:false,
				  
				
				
	          }
	        },
			components:{
				hzaddr,
				bjaddr,
				goTop
			},
			methods:{
				
			},
			mounted() {
				this.show=true;
			}
	   }
</script>

<style lang="scss" scoped>
	.fade-enter-active, .fade-leave-active {
	    transition: opacity 2s
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
	    opacity: 0
	}
	
	
	.connect {

		// 顶部banner部分
		header {
			// 图片顶部虚线
			position: relative;

			.top {
				height: 0px;
				border-top: 1px dashed dimgray;
			}

			// 中间Banner部分
			.center {
				img {
					width: 100%;
				}
			}

			// 图片底部虚线
			.bottom {
				width: 100%;
				border-top: 1px dashed dimgray;
				position: absolute;
				top: 495px;
				z-index: 100 !important;
			}
		}

		// 中间部分
		main {
			margin-top: 52px;
			margin-bottom: 157px;
			position: relative;
			.top {
				width: 1440px;
				background: #FFFFFF;
				box-shadow: 0px 5px 18px 2px rgba(0, 0, 0, 0.1);
				border-radius: 10px;
				margin: 0 auto;
				margin-top: -162px;
				padding-bottom: 77px;
				z-index: 1000 !important;

				.douhao{
					width: 112px;
					height: 46px;
					margin-left: 162px;
					img{
						height: 100%;
					}
				}

				.el-row{
					margin: 16px 74px;
					margin-top: 82px;
					margin-bottom: 141px;
					.el-col:first-child{
						h1{
							font-size: 30px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #2D2D2D;
						}
						// 渐变线
						.line-linear{
							width: 120px;
							height: 8px;
							background: linear-gradient(90deg, #194FA8, #FFFFFF);
							margin: 24px 0px 41px 0px;
						}
						ul{
							li{
								font-size: 20px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #666666;
								line-height: 36px;
								a{
									padding-left: 5px;
								}
								i{
									display: inline-block;
									font-size: 23px;
									margin-right: 14px;
								}
								i:first-child{
									margin-left: 5px;
								}
								img{
									width: 20px;
									height: 20px;
									margin: 0;
									margin-top: -5px;
									margin-right: 14px;
								}
							}
							li:first-child{
								margin-left: -7px;
							}
						}
					}
					.el-col:last-child{
						width: 784px;
						height: 460px;
					}
				}
				.el-row:last-child{
					margin-bottom: 0px;
				}
			}

		
		}
		
		// 右下角省略号图
		.more{
			position: relative;
			width: 100%;
			img{
				position: absolute;
				right: 0;
				bottom: -100px;
				width: 143px;
				height: 409px;
				z-index: -1;
			}
		}
	}
</style>
